<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h1>Releases History</h1>
            <mdb-row>
              <mdb-col md="12">
                <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
                <mdb-spinner v-if="isloading" />
                <mdb-timeline v-if="releases.length">
                  <mdb-timeline-item color="light-blue lighten-2" icon="cloud-upload-alt" :inverted="index%2" v-for="(release, index) in releases" v-bind:key="index" :title="getItemTitle(release.releasedate)">
                    <mdb-row v-for="(commit,index2) in release.commits" v-bind:key="`${index}_${index2}`">
                      <mdb-col md="12">
                        <hr />
                        <div class="text-muted mb-2">
                          <div class="mb-2 text-dark">
                            <mdb-icon icon="user-astronaut"></mdb-icon> Sylvain Mallet<br />
                          </div>
                          <span v-html="commit.message"></span>
                        </div>
                      </mdb-col>
                    </mdb-row>
                  </mdb-timeline-item>
                </mdb-timeline>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbAlert,
  mdbSpinner,
  mdbTimeline,
  mdbTimelineItem,
  mdbIcon,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'releasehistory',
  data() {
    return {
      releases: [],
      isloading: true,
      error: null,
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbAlert,
    mdbSpinner,
    mdbTimeline,
    mdbTimelineItem,
    mdbIcon,
  },
  mounted() {
    this.loadLast30Days();
  },
  methods: {
    loadLast30Days(from, to) {
      this.error = null;
      const self = this;
      const api = new Apicall();
      this.isloading = true;
      api.call('GET', 'api/apprelease/last-releases-30-days').then((response) => {
        if (response.status === 'OK') {
          self.releases = response.data;
        } else {
          self.error = response.msg;
        }
        self.isloading = false;
      });
    },
    getItemTitle(_thedate) {
      return `Déployé le ${this.formatDate(_thedate)}`;
    },
    formatDate(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
  },
};
</script>
